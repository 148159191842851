import { Component, ViewChild } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NavController, Platform, ViewWillEnter, MenuController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Router } from '@angular/router';

import { LanguageSetup } from './services/language-setup';
import { ViewEncapsulation } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Const } from './services/const';
import { StorageWrapper } from './services/storage-wrapper';
import { UserStatusManager } from './services/user-status-manager';
import { LogUtil } from './utilities/log-util';
import { Events } from './services/events';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements ViewWillEnter {
  //rootPage: any = T1cLoginPage;

  debug_mode = "OFF";
  public user_name: string = "";

  // USER情報
  public userIcon: string;
  public userName: string;

  // 言語情報
  public lang_img: string;

  //ダブルクリック防止
  private canClick: boolean = true;

  private logger: LogUtil = new LogUtil("90", "Menu");;

  @ViewChild('mycontent') navCtrl: NavController;

  constructor(
    private swUpdate: SwUpdate,
    private router: Router,
    public platform: Platform,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    public scrOrient: ScreenOrientation,
    public storageWrapper: StorageWrapper,
    public langSetup: LanguageSetup,
    public translate: TranslateService,
    public events: Events,
    public userStatusManager: UserStatusManager,
    private menu: MenuController
  ) {
    // アプリバージョンチェック
    swUpdate.available.subscribe((event) => {
      // アプリバージョンが更新されている場合、アップデートの通知を受け取る
      swUpdate.activateUpdate().then(() => {
        // アップデートの完了後（キャッシュ更新後）、アプリを更新（ページをリロード）
        document.location.reload();
      });
    });

    // USER情報
    this.userIcon = "";
    this.userName = "";

    // // 言語設定
    // this.langSetup.load();

    if (platform.is('cordova')) {
      this.scrOrient.lock(this.scrOrient.ORIENTATIONS.PORTRAIT);
    }

    platform.backButton.subscribe(() => {
      // code that is executed when the user pressed the back button
    })

    platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      statusBar.styleDefault();
      splashScreen.hide();
    });

    this.onEvent();
  }
  ionViewWillEnter(): void {
    this.setLanguageImage();
  }

  /** 
   * イベント処理
   * @note 本サブスクライバはアプリ起動中はクリアしてはならない。クリアした場合、サイドメニュが動作不良を起こす。
   */
  onEvent() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setLanguageImage();
    });

    this.events.subscribe(Const.EVENT_SIDEMENU_UPDATE, (data) => {
      this.userName = data.userName;
      this.userIcon = data.userIcon;
    });
  }

  changeDebug() {
    if (this.debug_mode == "ON") {
      this.debug_mode = "OFF";
      this.storageWrapper.setStorage("debugMode", this.debug_mode);
    } else {
      this.debug_mode = "ON";
      this.storageWrapper.setStorage("debugMode", this.debug_mode);
    }
  }

  // ページ読み込み開始時
  openMenu() {
    this.logger.pageLog();
    this.setLanguageImage();
  }

  closeMenu() {
    this.canClick = true;
  }

  setLanguageImage() {
    // 言語設定
    if (this.langSetup.lang === "en") {
      this.lang_img = "icon_ja";
    } else {
      this.lang_img = "icon_en";
    }
  }

  tapChangeAccount() {
    // ストレージを削除してログイン画面に遷移
    this.logger.tapLog("tapChangeAccount");
    this.toLoginPage();
    this.userStatusManager.finalize();
  }

  async toLoginPage() {
    /*** ユーザーIDの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_USER_ID);
    /*** ログインIDの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_LOGIN_ID);
    /*** ログインパスワードの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_LOGIN_PASSWORD);
    /*** ユーザアイコンURLの削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_USER_ICON);
    /*** ユーザー名の削除 ***/
    await this.storageWrapper.removeStorage(Const.STORAGE_KEY_USER_NAME);

    // TODO  this.navCtrl.setRoot(T1cLoginPage);
    this.menu.close();
    this.router.navigate(["login"]);
  }

  async ngOnInit() {
    // ストレージ初期化
    await this.storageWrapper.init();
    // 言語設定
    await this.langSetup.load();
  }
}
