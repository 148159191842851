export class LogUtil {

    private pageInfo: string;

    constructor(pageNo: string, pageName: string) {
        this.pageInfo = pageNo + ": " + pageName;
    }

    pageLog() {
        console.log("[Page] " + this.pageInfo);
    }

    tapLog(buttonName: string, message?: string) {
        let output = "[Tap] " + this.pageInfo + ": " + buttonName;

        if (message != null) {
            output += (": " + message);
        }

        console.log(output);
    }
}