/**
 * ユーザオプション.アイテムネーム タイプ.
 */
export const UserOptionItemNameType = {
  HOME_STATION_CODE: 'home-station-code',
  PRIVATE_STATION_CODES: 'private-station-codes',
  REQUESTABLE_RESERVATION: 'requestable-reservation',
  REQUIRED_STATION_CODE: 'required-station-code',
  REQUIRED_STATION_RADIUS_DISTANCE: 'required-station-radius-distance'
};

export type UserOptionItemNameType = typeof UserOptionItemNameType[keyof typeof UserOptionItemNameType];
