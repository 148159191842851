export const Environment = {
  production: true,
  /**
   * マップの切り替え.
   *
   * - none : マップ使用しない
   * - osm : OpenStreetMap
   * - google : Google Map
   *    `index.html` に `<script src="https://maps.googleapis.com/maps/api/js?key=${GoogleMapApikey}" async defer></script>` を指定してください。
   */
  map: 'google',
  /** デフォルト乗車人数. */
  defaultRideCount: 1,
  /** 最小乗車人数. */
  minRideCount: 1,
  /** 最大乗車人数. */
  maxRideCount: 8,
  /** デフォルト荷物数. */
  defaultCargoCount: 1,
  /** 最小荷物数. */
  minCargoCount: 1,
  /** 最大荷物数. */
  maxCargoCount: 3,
  /** 必須乗車地付近にいるかを判断するインターバル(ミリ秒). */
  requiredStationNearInterval: 30000,
  /** 歩行速度（単位 : km/h）デフォルト : 4.8km/h（時速） ※分速の場合、80m/min */
  walkVelocity: 4.8,
  /** サービサー接続用URL. */
  baseUrl: 'https://mslab.natcsv.com/reserve/v2/user/',

  /** 
   * 住所CSV取得先URL.
   * 
   * - 本番リリース時 : https://${fqdn}/reserve/v2/image?name=stop_point/data.csv
   */
  addressCsvUrl: 'https://mslab.natcsv.com/reserve/v2/image?name=stop_point/data.csv',
  /** オートコンプリートの有効化. */
  isEnabledAutocomplete: false,
  /** BLEの有効. */
  isEnabledBle: false,
  /** ServiceWorkerModule の登録.（PWA 実行時に true） */
  serviceWorkerRegister: true,

  /** 地図初期表示（緯度）. */
  mapInitialStateLat: 35.463029060,
  /** 地図初期表示（経度）. */
  mapInitialStateLng: 139.625802500,
  /** 地図初期表示（ズーム）. */
  mapInitialStateZoom: 20
};